@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding:0;
  box-sizing:border-box;
  font-family: 'Lexend', sans-serif;
}

:root {
  --color-primary: #EC6608;
  --color-secondary: #FFF1F1;
  --color-tertiary: #07301E;
  --color-background: #ffffff;
  --color-backgroundGray: #F3F3F3;
  --color-text: #565656;
  --color-black: #000000;
  --color-white: #ffffff;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
}


@layer base {
  h1 {
    font-size: 34px;
    line-height: 40.08px;
    font-weight: 600;
    text-align: center;
    color: var(--color-black);

    @screen sm {
      font-weight: 600 !important; 
      font-size: 45px !important;
      line-height: 55px !important;
    }

    @screen xl {
      font-size: 62px !important;
      line-height: 74px !important;
    }
  }

  h2 {
    font-size: 31px;
    line-height: 36.55px;
    font-weight: 600;
    text-align: center;
    color: var(--color-tertiary);

    @screen sm {
      font-size: 37px;
      line-height: 42px;
    }

    @screen xl {
      font-size: 44px;
      line-height: 48.4px;
    }
  }

  h3 {
    font-size: 18px;
    line-height: 22.5px;
    font-weight: 500;
    text-align: center;
    color: var(--color-background);

    @screen sm {
      font-size: 21px;
      line-height: 26px;
    }

    @screen xl {
      font-size: 24px;
      line-height: 30px;
    }
  }

  h4 {
    font-size: 17px;
    line-height: 27.2px;
    font-weight: 400;
    color: var(--color-text);

    @screen sm {
      font-size: 19px;
      line-height: 29px;
    }

    @screen xl {
      font-size: 20px;
      line-height: 32px;
    }
  }

  h5 {
    font-size: 18px;
    line-height: 25.2px;
    font-weight: 400;
    text-align: center;
    color: var(--color-text);
  }

  h6 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: var(--color-white);
  }
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.hide-scrollbar {
  scrollbar-width: none; /* For Firefox */
}

@layer utilities {
  .clip-custom {
    clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 0 100%);
  }
}
